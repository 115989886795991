import styles from './navtarget.module.css';
import React from "react";
import { useLocation, NavLink } from "react-router-dom";

interface Props {
    children: React.ReactNode;
    to: string;
}
const NavTarget = (props: Props) => {
    const location = useLocation();
    // TODO do somethis with location to set active class (when there are more routes)
    return <NavLink to={props.to} style={{ textDecoration: 'none'}}>
        <div className={styles.navTarget}>
            {props.children}
            <div className={styles.underline}></div>
        </div>
    </NavLink>;
}

export default NavTarget;
