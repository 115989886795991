import styles from './createEntry.module.css';
import env from './../../environment/environment';
import axios from 'axios';
import {useState, useContext} from "react";
import {getLocalDateTime} from "../../Util/DateHelper";
import {UserContext} from "../../Provider/UserProvider";
import {EntryDTO} from "../../DTO/EntryDTO";
import {ModalContext} from "../../Provider/ModalProvider";
import {ApplicationStateContext} from "../../Provider/ApplicationStateProvider";

interface FormState {
    title: string;
    startDate: number;
    endDate: number;
    allDay: boolean;
    series: number;
}

const CreateEntry = () => {
    const { loginToken, user } = useContext(UserContext);
    const { setActiveModal, modalData, setData }  = useContext(ModalContext);
    const { setLoading } = useContext(ApplicationStateContext);
    const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        /*const realStartDate = formState.startDate - offset;
        const realEndDate = formState.endDate - offset;*/
        if (formState.title === '') {
            alert('Titel darf nicht leer sein');
            return;
        }
        if (formState.startDate> formState.endDate) {
            alert('Enddatum darf nicht vor Startdatum liegen');
            return;
        }
        if (!user) {
            alert('Bitte loggen Sie sich ein');
            return;
        }
        const data: EntryDTO = {
            title: formState.title,
            startDate: formState.startDate,
            endDate: formState.endDate,
            holeDay: formState.allDay,
            seriesType: formState.series,
            userId: user.user.id,
            id: undefined,
        }
        setLoading(true);
        axios({
            method: 'post',
            url: env.apiRoute + '/newEntry',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + loginToken,
            },
            data: {
                data: data,
            }
        }).then(() => {
            setActiveModal('none');
            setData(undefined);
            resetModal();
        }).catch(
            () => {
                alert('Fehler beim Speichern des Eintrags');
            }
        ).finally(
            () => setLoading(false)
        );
    }
    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        if (name === 'startDate' || name === 'endDate') {
            setFormState({...formState, [name]: Date.parse(value)});
            return;
        }
        if (name === 'allDay') {
            setFormState({...formState, [name]: event.target.checked});
            return;
        }
        if (name === 'series') {
            setFormState({...formState, [name]: event.target.checked ? 2 : 0});
            return;
        }
        setFormState({...formState, [name]: value});
    }

    const selChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const {value} = event.target;
        setFormState({...formState, series: Number(value)});
    }

    const [formState, setFormState] = useState({
        title: '',
        startDate: modalData !== undefined
            ? (new Date(modalData.startDate).getHours() === 0
                ? new Date(new Date(modalData.startDate).setHours(new Date().getHours())).setMinutes(new Date().getMinutes())
                : modalData.startDate)
            : new Date().getTime(),
        endDate: modalData !== undefined
            ? (new Date(modalData.startDate).getHours() === 0
                ? new Date(new Date(modalData.startDate).setHours(new Date().getHours())).setMinutes(new Date().getMinutes()) + 3600000
                : modalData.startDate + 3600000)
            : new Date().getTime() + 3600000, // 1 hour later
        allDay: false,
        series: 0,
    });

    const resetModal = () => {
        setFormState({
            title: '',
            startDate: modalData === undefined ? new Date().getTime() : modalData.startDate,
            endDate: modalData === undefined ? new Date(Date.now() + 3600000).getTime() : modalData.endDate, // 1 hour later
            allDay: false,
            series: 0,
        });
    }

    return (<div className={styles.createEntry}>
        <h1>Eintrag Anlegen</h1>
        <form onSubmit={submitHandler} className={styles.form}>
            <label className={styles.label}>Titel<br />
                <input className={styles.textInput}
                       value={formState.title}
                       type="text"
                       placeholder="Titel"
                       name='title'
                       onChange={changeHandler}
                       maxLength={30}/>
            </label><br />
            <label className={styles.label}>Start<br />
                <input className={styles.dateTimeInput}
                       value={getLocalDateTime(formState.startDate)}
                       type="datetime-local"
                       onChange={changeHandler}
                       name='startDate' />
            </label><br />
            <label className={styles.label}>Ende<br />
                <input className={styles.dateTimeInput}
                       value={getLocalDateTime(formState.endDate)}
                       type="datetime-local"
                       name='endDate'
                       onChange={changeHandler}/>
            </label><br />
            <label className={styles.label + ' ' + styles.checkboxLabel}>Ganzer Tag
                <input id='day' checked={formState.allDay}
                       className={styles.checkbox}
                       type="checkbox"
                       name='allDay'
                       onChange={changeHandler}/>
            </label><br />
            <label className={styles.label + ' ' + styles.checkboxLabel}>Serie
                <input id='day' checked={formState.series !== 0}
                       className={styles.checkbox}
                       type="checkbox"
                       name='series'
                       onChange={changeHandler}/>
            </label><br />
                { formState.series !== 0 ? (<>
                <label className={styles.label}>Wiederholungsart<br />
                    <select className={styles.select}
                            value={formState.series}
                            name='series-sel'
                            onChange={selChangeHandler}>
                        <option value="1">Täglich</option>
                        <option value="2">Wöchentlich</option>
                        <option value="3">Monatlich</option>
                        <option value="4">Jährlich</option>
                    </select>
                </label><br />
                </>)
                : '' }
            <input type='submit' value='Anlegen' className={styles.submit}/>
        </form>
    </div>);
}

export default CreateEntry;
