import styles from './kalenderView.module.css';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGrid from '@fullcalendar/timegrid';
import multiMonth from '@fullcalendar/multimonth';
import dayGrid from '@fullcalendar/daygrid';
import list from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import FullCalendar from "@fullcalendar/react";
import axios from "axios";
import env from "../../environment/environment";
import {UserContext} from "../../Provider/UserProvider";
import React, {useContext, useEffect} from "react";
import {EntryDTO} from "../../DTO/EntryDTO";
import {ModalContext} from "../../Provider/ModalProvider";
import interactionPlugin from '@fullcalendar/interaction';

const KalenderView = () => {
    const { user, loginToken } = useContext(UserContext);
    const { activeModal, setActiveModal, setData } = useContext(ModalContext);
    const calendarRef = React.createRef<FullCalendar>();
    const eventsFunction = (fetchInfo: any, successCallback: any, errorCB: any) => {
        const from = fetchInfo.start.getTime();
        const to = fetchInfo.end.getTime();
        axios({
            method: 'post',
            url: `${env.apiRoute}/getEntriesBetween/${from}/${to}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + loginToken,
            },
        }).then((response) => {
            const res = response.data.map((entry: EntryDTO) => {
                const endDate = new Date(entry.endDate);
                if (entry.holeDay) {
                    endDate.setDate(endDate.getDate() + 1);
                }
                return {
                    title: entry.title,
                    start: new Date(entry.startDate),
                    end: endDate,
                    allDay: entry.holeDay,
                    extendedProps: {
                        id: entry.id,
                        seriesType: entry.seriesType,
                    }
                };
            });
            successCallback(res);
        }).catch((error) => {
            console.error(error);
            errorCB(error);
        });
    }

    useEffect(() => {
        if (activeModal === 'none')
            calendarRef.current?.getApi().refetchEvents();
    }
    , [activeModal]);

    const handleEventClick = (clickInfo: any) => {
        const e = clickInfo.event;
        const exProps = clickInfo.event.extendedProps;
        if ((e.start as Date).getHours() === 0) {
            (e.startDate as Date)
        }
        setData({
            id: exProps.id,
            title: e.title,
            startDate: e.start.getTime(),
            endDate: (e.end as Date).setDate((e.end as Date).getDate() - (e.allDay ? 1 : 0)),
            allDay: e.allDay,
            series: exProps.seriesType,
        });
        setActiveModal('editEntry');
    }

    const handleDateClick = (clickInfo: any) => {
        console.log((clickInfo.date as Date).toISOString());
        setData({
            startDate: clickInfo.date.getTime(),
            endDate: clickInfo.date.getTime() + 3600000, // 1 hour
        });
        setActiveModal('createEntry');
    }

    const mobile = window.innerWidth < 600;
    return <div className={styles.calendarWrapper}>
        <FullCalendar
            eventClick={handleEventClick}
            dateClick={handleDateClick}
            ref={calendarRef}
            plugins={ [dayGridPlugin, timeGrid, multiMonth, dayGrid, list, timeGridPlugin, interactionPlugin] }
            height={window.innerHeight - 115}
            aspectRatio={1.35}
            initialView={ mobile ? 'dayGridMonth' : 'timeGridWeek'}
            weekends={true}
            firstDay={1}
            weekNumbers={true}
            nowIndicator={true}
            headerToolbar={{
                left: mobile ? undefined : 'multiMonthYear,dayGridMonth,dayGridWeek,dayGridDay,listWeek,timeGridWeek,timeGridDay',
                right: 'today,prev,next',
                center: 'title',
            }}
            footerToolbar={{
                left: mobile ? 'dayGridMonth,dayGridWeek,dayGridDay,listWeek,timeGridWeek,timeGridDay' : undefined
            }}
            events={ eventsFunction }
        ></FullCalendar>
    </div>;
}
export default KalenderView;
