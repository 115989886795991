import { createBrowserRouter } from 'react-router-dom';
import Content from '../Components/Content';
import Login from '../Components/Login';
import Home from '../Components/Home'
import ErrorPage from '../Components/ErrorPage';
import LoggedInGuard from '../Security/LoggedInGuard';
import Termine from "../Components/Termine/Termine";
import Series from "../Components/Series/Series";
import KalenderView from "../Components/Calendar/KalenderView";

const router = createBrowserRouter([
    { path: '/', element: <Content />, errorElement: <ErrorPage />, children: [
        { path: '/', element: <LoggedInGuard />, children: [
            {path: '/', element: <Home />},
            {path: '/termine', element: <Termine />},
            {path: '/series', element: <Series />},
            {path: '/calendar', element: <KalenderView />},
        ]},
    ]},
    { path: '/login', element: <Login />}
]);

export default router;
