import styles from './termine.module.css';
import {useContext, useEffect, useState} from "react";
import {UserContext} from "../../Provider/UserProvider";
import axios from "axios";
import env from "../../environment/environment";
import Entry from "../Entrys/Entry";
import {EntryDTO, SeriesType} from "../../DTO/EntryDTO";
import {ModalContext} from "../../Provider/ModalProvider";
import {getLocalDate} from "../../Util/DateHelper";
import {ApplicationStateContext} from "../../Provider/ApplicationStateProvider";

const Termine = () => {
    const  { user, loginToken } = useContext(UserContext);
    const [entries, setEntries] = useState([]);
    const { activeModal } = useContext(ModalContext);
    const { loading, setLoading } = useContext(ApplicationStateContext);
    const [ fromTo, setFromTo ] = useState({
        from: Date.now() - 100000000 , // 1 day
        to: Date.now() + 604800000, // 7 days
    });
    const changeFrom = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFromTo({
            from: new Date(e.target.value).getTime(),
            to: fromTo.to,
        });
    }
    const changeTo = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFromTo({
            from: fromTo.from,
            to: new Date(e.target.value).getTime(),
        });
    }
    const refresh = () => {
        setLoading(true);
        axios({
            method: 'post',
            url: `${env.apiRoute}/getEntriesBetween/${fromTo.from}/${fromTo.to}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + loginToken,
            },
        }).then((response) => {
            setEntries(response.data.filter((entry: EntryDTO) => entry.seriesType === SeriesType.NONE));
        }).catch((error) => {
            console.error(error);
        }).finally(
            () => setLoading(false)
        );
    }
    useEffect(() => {
        refresh();
    }, [activeModal, fromTo]);

    const onEntryDelete = () => {
        refresh();
    }

    return <>
        <h1>Alle Termine</h1>
        <form className={styles.form}>
            <label>Von
                <input type='date' onChange={changeFrom} value={getLocalDate(fromTo.from)} className={styles.dateTimeInput}/>
            </label>
            <label>Bis
                <input type='date' onChange={changeTo} value={getLocalDate(fromTo.to)} className={styles.dateTimeInput} />
            </label>
        </form>
        <div className={styles.entryWrapper}>
        {entries.map((entry: EntryDTO) => {
            return <Entry entry={entry} onDelete={onEntryDelete} key={entry.startDate + entry.endDate}/>;
        })}
        </div>
    </>
}

export default Termine;
