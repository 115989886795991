import React, {ReactNode} from 'react';
import useUser from '../Hooks/useUser';
import axios from 'axios';
import env from '../environment/environment';
import {UserInfoDto} from "../DTO/UserDtos";

const UserContext = React.createContext({
    user: {"roles":[],"user":{"username":"testuser","roleIds":[],"id":"6426b737f5039aef991752c3"},"services":[]} as UserInfoDto | undefined,
    loggedIn: false,
    loginToken: undefined as string | undefined,
    login: async (creds: string): Promise<boolean> => { return false; },
});

interface Props {
    children?: ReactNode
    // any props that come into the component
}

const UserProvider = ({ children }: Props) => {
    const {user, setUser, loggedIn, setLoggedIn, loginToken, setLoginToken} = useUser();

    const login = async (credentials: string) => {
        const loginPromise = new Promise<boolean>((resolve) => {
            if (env.production === false) {
                setLoggedIn(true);
                setLoginToken('dGVzdHVzZXI6cGFzc3dvcmQ=');
                resolve(true);
            }
            const pullUserInfo = (token: string) => {
                return axios({
                    method: 'get',
                    url: env.apiServiceRoute + '/trylogin',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Basic ' + token, 
                    }
                });
            };

            if (credentials === '') {
                return;
            }

            pullUserInfo(credentials).then((response) => {
                if (response.status === 200 && response.data.services.filter((service: any) => service.insideUrl === 'calendar').length > 0) {
                    const token = credentials;
                    localStorage.setItem('user', token);
                    setLoginToken(token);
                    setUser(response.data);
                    setLoggedIn(true);
                    resolve(true);
                }
                else {
                    console.error(response);
                    localStorage.removeItem('user');
                    resolve(false)
                }
            }).catch((error) => {
                console.error(error);
                localStorage.removeItem('user');
                resolve(false);
            });
        });
        return loginPromise;
    };
    return (
        <UserContext.Provider value={{ user, loggedIn, loginToken, login }}>
            {children}
        </UserContext.Provider>
    );
}
export default UserProvider;
export { UserContext };
