import UserProvider from './UserProvider';
import { RouterProvider } from 'react-router-dom';
import LoginRouter from '../Router/Router';
import React from "react";
import {ApplicationStateProvider} from "./ApplicationStateProvider";
import ModalProvider from "./ModalProvider";
interface IProps {
    children?: React.ReactNode;
}
const ProviderComponent = ({children}: IProps) => {
    return (
        <ApplicationStateProvider>
            <ModalProvider>
                <UserProvider>
                    <RouterProvider router={LoginRouter}/>
                </UserProvider>
            </ModalProvider>
        </ApplicationStateProvider>
    );
};
export default ProviderComponent;
