import { Outlet } from 'react-router-dom';
import Navbar from './Navbar/Navbar';
import ModalContainer from "./Modals/ModalContainer";
import AddButton from "./Buttons/AddButton";
import styles from './styles/content.module.css';
const Content = () => {
    return (
        <div>
            <Navbar />
            <div className={styles.outletWrapper}>
                <Outlet />
            </div>
            <ModalContainer />
            <AddButton />
        </div>
    );
};
export default Content;
