import { useState, useEffect, useContext } from 'react';
import axios from "axios";
import env from '../../environment/environment'
import {UserContext} from "../../Provider/UserProvider";
import Entry from "../Entrys/Entry";
import {EntryDTO} from "../../DTO/EntryDTO";
import styles from './homeCom.module.css';
import {ModalContext} from "../../Provider/ModalProvider";
import {ApplicationStateContext} from "../../Provider/ApplicationStateProvider";
const HomeComponent = () => {
    const  { user, loginToken } = useContext(UserContext);
    const [entries, setEntries] = useState([]);
    const { activeModal } = useContext(ModalContext);
    const { setLoading } = useContext(ApplicationStateContext);

    const refresh = () => {
        setLoading(true);
        axios({
            method: 'post',
            url: `${env.apiRoute}/getEntriesBetween/${Date.now()}/${Date.now() + 604800000}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + loginToken,
            },
        }).then((response) => {
            setEntries(response.data);
        }).catch((error) => {
            console.error(error);
        }).finally(
            () => setLoading(false)
        );
    }

    useEffect(() => {
        refresh();
    }, [activeModal]);

    return <div className={styles.homeWrapper}>
        <h1>Termine der nächsten 7 Tage</h1>
        {entries.map((entry: EntryDTO) => {
            return <Entry entry={entry} key={entry.startDate} onDelete={refresh}/>
        })}
        { entries.length === 0 ? <p>Keine Termine in den nächsten 7 Tagen</p> : null }
    </div>;
}
export default HomeComponent;
