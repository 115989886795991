import styles from './modalContainer.module.css';
import CreateEntryModal from './CreateEntry'
import { useContext } from 'react';
import {ModalContext} from "../../Provider/ModalProvider";
import React from "react";
import EditEntry from "./EditEntry";
const ModalContainer = () => {
    const {activeModal,setActiveModal} = useContext(ModalContext);
    const handleClick = (event: React.MouseEvent) => {
        if (event.target === event.currentTarget)
            setActiveModal('none');
    }
    return <>
        { activeModal !== 'none' &&
            (<div className={styles.modalContainer + ' ' + styles.active} onClick={handleClick}>
                { activeModal === 'createEntry' && <CreateEntryModal /> }
                { activeModal === 'editEntry' && <EditEntry /> }
            </div>)
        }
    </>;
}
export default ModalContainer;
