import styles from './addButton.module.css'
import { useContext } from "react";
import {ModalContext} from "../../Provider/ModalProvider";

const AddButton = () => {

    const { activeModal, setActiveModal, setData} = useContext(ModalContext);

    const handleClick = () => {
        if (activeModal === 'none') {
            setData(undefined);
            setActiveModal('createEntry');
        } else {
            setActiveModal('none');
        }
    }

    return (
        <div className={styles.addButton} onClick={handleClick}>
            <span className={styles.plus}>+</span>
        </div>
    );
}
export default AddButton;
