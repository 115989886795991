import styles from './Entry.module.css';
import {EntryDTO, SeriesType} from "../../DTO/EntryDTO";
import {UserContext} from "../../Provider/UserProvider";
import {useContext} from "react";
import axios from "axios";
import env from "../../environment/environment";
import {ModalContext} from "../../Provider/ModalProvider";
import {ApplicationStateContext} from "../../Provider/ApplicationStateProvider";
interface Props {
    entry: EntryDTO;
    onDelete: () => void;
}
const Entry = (props: Props) => {
    const { user, loginToken } = useContext(UserContext);
    const { setActiveModal, setData } = useContext(ModalContext);
    const {setLoading } = useContext(ApplicationStateContext);

    const onDelete = () => {
        setLoading(true);
        axios({
            method: 'delete',
            url: `${env.apiRoute}/deleteEntry/${props.entry.id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + loginToken,
            },
        }).then((response) => {
            props.onDelete();
        }).catch((error) => {
            alert('Fehler beim löschen des Termins!');
            console.error(error);
        }).finally(
            () => setLoading(false)
        );
    }
    const onEdit = () => {
        setData({
            id: props.entry.id,
            title: props.entry.title,
            startDate: props.entry.startDate,
            endDate: props.entry.endDate,
            allDay: props.entry.holeDay,
            series: props.entry.seriesType,
        });
        setActiveModal('editEntry');
    }
    const entry = props.entry;
    return <div className={styles.entry}>
        <div className={styles.title}>
            <div>
                { entry.seriesType === SeriesType.NONE ? <div className={styles.placeholder} /> : <img src='./wiederholung.png' className={styles.img + ' ' + styles.filter}/> }
            </div>
            { entry.title }
            <div>
                { entry.holeDay ? <img src='./holeDay.png' className={styles.img + ' ' + styles.filter} /> : <div className={styles.placeholder} /> }
            </div>
        </div>
        <div className={styles.from}>
            { new Date(entry.startDate).toDateString() + ' ' }
            { !entry.holeDay && new Date(entry.startDate).toLocaleTimeString() }
        </div>
        <div className={styles.to}>
            { new Date(entry.endDate).toDateString() + ' ' }
            { !entry.holeDay && new Date(entry.endDate).toLocaleTimeString() }
        </div>
        { entry.seriesType !== SeriesType.GENERATED && <>
            <div className={styles.divider}/>
            <div className={styles.actions} >
                <button className={styles.button} onClick={onDelete}>
                    <svg version="1.1"
                         viewBox="0 0 482.428 482.429"
                         className={styles.deleteSvg}>
                        <g>
                            <g>
                                <path d="M381.163,57.799h-75.094C302.323,25.316,274.686,0,241.214,0c-33.471,0-61.104,25.315-64.85,57.799h-75.098
                                    c-30.39,0-55.111,24.728-55.111,55.117v2.828c0,23.223,14.46,43.1,34.83,51.199v260.369c0,30.39,24.724,55.117,55.112,55.117
                                    h210.236c30.389,0,55.111-24.729,55.111-55.117V166.944c20.369-8.1,34.83-27.977,34.83-51.199v-2.828
                                    C436.274,82.527,411.551,57.799,381.163,57.799z M241.214,26.139c19.037,0,34.927,13.645,38.443,31.66h-76.879
                                    C206.293,39.783,222.184,26.139,241.214,26.139z M375.305,427.312c0,15.978-13,28.979-28.973,28.979H136.096
                                    c-15.973,0-28.973-13.002-28.973-28.979V170.861h268.182V427.312z M410.135,115.744c0,15.978-13,28.979-28.973,28.979H101.266
                                    c-15.973,0-28.973-13.001-28.973-28.979v-2.828c0-15.978,13-28.979,28.973-28.979h279.897c15.973,0,28.973,13.001,28.973,28.979
                                    V115.744z"/>
                                <path d="M171.144,422.863c7.218,0,13.069-5.853,13.069-13.068V262.641c0-7.216-5.852-13.07-13.069-13.07
                                    c-7.217,0-13.069,5.854-13.069,13.07v147.154C158.074,417.012,163.926,422.863,171.144,422.863z"/>
                                <path d="M241.214,422.863c7.218,0,13.07-5.853,13.07-13.068V262.641c0-7.216-5.854-13.07-13.07-13.07
                                    c-7.217,0-13.069,5.854-13.069,13.07v147.154C228.145,417.012,233.996,422.863,241.214,422.863z"/>
                                <path d="M311.284,422.863c7.217,0,13.068-5.853,13.068-13.068V262.641c0-7.216-5.852-13.07-13.068-13.07
                                    c-7.219,0-13.07,5.854-13.07,13.07v147.154C298.213,417.012,304.067,422.863,311.284,422.863z"/>
                            </g>
                        </g>
                    </svg>
                </button>
                <button className={styles.button} onClick={onEdit}>
                    <svg viewBox="0 0 91 91" version="1.1" className={styles.copySvg}>
                        <path d="M77.926,94.924H8.217C6.441,94.924,5,93.484,5,91.706V21.997c0-1.777,1.441-3.217,3.217-3.217h34.854 c1.777,0,3.217,1.441,3.217,3.217s-1.441,3.217-3.217,3.217H11.435v63.275h63.274V56.851c0-1.777,1.441-3.217,3.217-3.217 c1.777,0,3.217,1.441,3.217,3.217v34.855C81.144,93.484,79.703,94.924,77.926,94.924z"/>
                        <path d="M94.059,16.034L84.032,6.017c-1.255-1.255-3.292-1.255-4.547,0l-9.062,9.073L35.396,50.116 c-0.29,0.29-0.525,0.633-0.686,1.008l-7.496,17.513c-0.526,1.212-0.247,2.617,0.676,3.539c0.622,0.622,1.437,0.944,2.274,0.944 c0.429,0,0.858-0.086,1.276-0.257l17.513-7.496c0.375-0.161,0.719-0.397,1.008-0.686l35.026-35.026l9.073-9.062 C95.314,19.326,95.314,17.289,94.059,16.034z M36.286,63.79l2.928-6.821l3.893,3.893L36.286,63.79z M46.925,58.621l-5.469-5.469 L73.007,21.6l5.47,5.469L46.925,58.621z M81.511,24.034l-5.469-5.469l5.716-5.716l5.469,5.459L81.511,24.034z"/>
                    </svg>
                </button>
            </div>
        </>}
    </div>;
}

export default Entry;
