const offset = new Date().getTimezoneOffset() * 1000 * 60;
const getLocalDateTime = (value: number) => {
    const offsetDate = new Date(value).valueOf() - offset;
    const date = new Date(offsetDate).toISOString();
    return date.substring(0, 16);
};
const getLocalDate = (value: number) => {
    const offsetDate = new Date(value).valueOf() - offset;
    const date = new Date(offsetDate).toISOString();
    return date.substring(0, 10);
}

export { getLocalDateTime, getLocalDate, offset };
