import {createContext, useState} from "react";

const ModalContext = createContext({
    activeModal: 'none',
    modalData: {} as any,
    setData: (data: any) => {},
    setActiveModal: (modal: string) => {},
})

const ModalProvider = ({children}: any) => {
    const [activeModal, setActiveModal] = useState('none');
    const [data, setData] = useState({});
    return (
        <ModalContext.Provider value={{activeModal: activeModal, setActiveModal: setActiveModal, modalData: data, setData: setData}}>
            {children}
        </ModalContext.Provider>
    );
};

export default ModalProvider;
export {ModalContext};
