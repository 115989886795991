import styles from './series.module.css';
import {useContext, useEffect, useState} from "react";
import {UserContext} from "../../Provider/UserProvider";
import axios from "axios";
import env from "../../environment/environment";
import Entry from "../Entrys/Entry";
import {EntryDTO} from "../../DTO/EntryDTO";
import {ModalContext} from "../../Provider/ModalProvider";
import {ApplicationStateContext} from "../../Provider/ApplicationStateProvider";
const Series = () => {
    const  { user, loginToken } = useContext(UserContext);
    const [entries, setEntries] = useState([]);
    const { activeModal } = useContext(ModalContext);
    const { setLoading } = useContext(ApplicationStateContext);
    const refresh = () => {
        setLoading(true);
        axios({
            method: 'post',
            url: `${env.apiRoute}/getSeries`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + loginToken,
            },
        }).then((response) => {
            setEntries(response.data);
        }).catch((error) => {
            console.error(error);
        }).finally(
            () => setLoading(false)
        );
    }
    useEffect(() => {
        refresh();
    }, [activeModal]);

    return <>
        <h1>Alle Serien</h1>
        <div className={styles.entryWrapper}>
            {entries.map((entry: EntryDTO) => {
                return <Entry entry={entry} onDelete={refresh} />;
            })}
        </div>
    </>
}

export default Series;
