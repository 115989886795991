interface EntryDTO {
    id: number | undefined;
    userId: string;
    title: string;
    startDate: number;
    endDate: number;
    holeDay: boolean;
    seriesType: SeriesType;
}

enum SeriesType {
    NONE = 0,
    DAILY = 1,
    WEEKLY = 2,
    MONTHLY = 3,
    YEARLY = 4,
    GENERATED = 5,
}
export type {EntryDTO};
export {SeriesType};
